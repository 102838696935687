import * as React from "react"
import MSCarousel from "../components/carousel/carousel";
import Helmet from "../components/helmet/helmet";
import MainPage from "../components/mainPage/mainPage";
import NavBar from "../components/navbar/navbar";
import "./index.scss"
import image1 from "../images/image3.jpg"
import image2 from "../images/image4.jpg"
import image3 from "../images/image5.jpg"
import image4 from "../images/image6.jpg"
import image5 from "../images/image7.jpg"
import image8 from "../images/image8.jpg"
import sal from 'sal.js'
import Footer from "../components/footer/footer";

const IndexPage = (props) => {
  React.useEffect(() => {
    sal({
      once: true,
    });
  })
  const { location } = props;
  return (
    <div className="MainContainer">
      <Helmet />
      <NavBar location={location} />
      <MSCarousel images={[image8,image1, image2,image3,image4,image5]} />
      <MainPage />
      <Footer />
    </div>
  )
}

export default IndexPage;
