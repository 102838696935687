import React from "react";
import { Carousel } from "react-bootstrap";


const MSCarousel = (props) => {
    const {images}= props;
    return (
        <Carousel controls={false} interval={5000} fade={true} className="Carousel" variant="dark">
            {images.map((el,index) =>
                <Carousel.Item key={`image-${index}`}>
                    <img
                        className="d-block w-100"
                        src={el}
                        alt="First slide"
                    />
                </Carousel.Item>
            )
            }
        </Carousel>
    )
}


export default MSCarousel;