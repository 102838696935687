import React from 'react'
import "./mainPage.scss"
import logo from '../../images/logo1.png'
import casto from '../../images/casto.svg'

const MainPage = () => (
    <div className='MainPage'>
        <div className="SalContentContainer">
            <div
                className="text"
                data-sal="slide-right"
                data-sal-delay="500"
                data-sal-duration="1500"
                data-sal-easing="ease-in-sinee"
            >
                <h5>Jakość, dokładność, terminowość, doświadczenie.</h5>
                <h6>Jesteśmy firmą zajującą się szerokim zakresem usług związanych z wyrobami stolarskimi, montażem mebli, układaniem paneli podłogowych.
                Montujemy kuchnie, drzwi, szafy. Stawiamy przede wszystkim na zadowolenie klient, a każdą pracę wykonujemy z dbałością o każdy detal.</h6>
            </div>
            <div
                className='ImageHolder'
                data-sal="fade"
                data-sal-delay="500"
                data-sal-duration="1000"
                data-sal-easing="ease-in-sinee"
            >
                <img src={logo} alt="SMmontaze
                "/>
            </div>

        </div>
        <div className="SalContentContainer">
            <div
                className='ImageHolder'
                data-sal="fade"
                data-sal-delay="500"
                data-sal-duration="1000"
                data-sal-easing="ease-in-sinee"
            >
                <img src={casto}  alt="castorama"/>
            </div>
            <div
                className="text"
                data-sal="slide-left"
                data-sal-delay="500"
                data-sal-duration="1500"
                data-sal-easing="ease-in-sinee"
            >
                <h5>Współpracujemy z marką Castorama.</h5>
                <h6>Świadczymy usługi na poczet klientów sieci handlowej Castorama w Gnieźnie ul: Pałucka 1
                Cennik usług dla klientów Castorama zawarty jest w zakładce cenniki.</h6>
            </div>
        </div>
    </div>
)

export default MainPage;